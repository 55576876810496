
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { Info } from '@/types/scenic'
import { FileInfo } from '@/types/common'
import UploadFile from '@/components/uploadFile/Index.vue'

@Component({
  components: { UploadFile }
})

export default class Add extends Vue {
  private info: Info = {
    projectId: '',
    scenicIntroduce: '',
    imgList: [],
    videoResource: null,
    scenicType: ''
  }

  private rules = {
    projectId: [
      { required: true, message: '请选择项目', trigger: 'change' }
    ],
    scenicType: [
      { required: true, message: '请选择入园模式', trigger: 'change' }
    ],
    scenicIntroduce: [
      { required: true, message: '请输入景区介绍', trigger: ['blur', 'change'] }
    ],
    imgList: [
      { required: true, message: '请上传图片', trigger: 'change' }
    ]
  }

  private submitShow = false
  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.$route.params.id && this.getDetail()
  }

  getDetail () {
    this.$axios.get(this.$apis.scenic.selectScenicIntroduceByProjectId, {
      projectId: this.$route.params.id
    }).then(res => {
      this.info = res || {}
    })
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    this.info.imgList.push(file)
  }

  // 视频上传返回
  onSuccessVideo (file: FileInfo) {
    this.info.videoResource = file
  }

  imgRemove (index: number) {
    this.info.imgList.splice(index, 1)
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        if (this.$route.params.id) {
          this.info.oldProjectId = this.$route.params.id as string | undefined
        }
        this.$axios.post(this.$apis.scenic.insertUpdateScenicIntroduce, this.info).then(() => {
          this.$message.success('保存成功')
          this.$router.push({ name: 'scenicList' })
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
